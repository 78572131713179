import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

declare const Swal: any;

export type SweetAlertIcon = 'success' | 'error' | 'warning' | 'info' | 'question';

interface SweetAlertOptions {
  title?: string | HTMLElement;
  html?: string | HTMLElement;
  text?: string;
  icon?: SweetAlertIcon;
  input?: 'text' | 'date' | 'radio';
  inputAttributes?: any;
  inputLabel?: string;
  inputOptions?: any;
  inputValidator?: any;
  inputValue?: any;
  cancelButtonText?: string;
  confirmButtonText?: string;
  showCancelButton?: boolean;
  customClass?: {
    cancelButton?: string;
    confirmButton?: string;
    htmlContainer?: string;
    popup?: string;
  };
}

export interface SweetAlertResult {
  dismiss?: 'cancel';
  isConfirmed: boolean;
  isDenied: boolean;
  isDismissed: boolean;
  value: any;
}

@Injectable({ providedIn: 'root' })
export class AlertsService {

  private readonly _swal: any;

  constructor() {
    this._swal = Swal.mixin({
      buttonsStyling: false,
      confirmButtonColor: undefined,
      confirmButtonText: 'OK, got it!',
      customClass: {
        cancelButton: 'btn btn-light',
        confirmButton: 'btn btn-primary me-2'
      }
    });
  }

  cancel(): Promise<SweetAlertResult> {
    return this.fire({
      html: 'Are you sure you want to cancel?',
      icon: 'warning',
      cancelButtonText: 'No, return',
      confirmButtonText: 'Yes, cancel it!',
      showCancelButton: true
    });
  }

  confirm(title?: string, html?: string): Promise<SweetAlertResult> {
    return this.fire({
      title: title,
      html: html,
      icon: 'question',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes!',
      showCancelButton: true
    });
  }

  danger(title?: string, html?: string): Promise<SweetAlertResult> {
    return this.fire({
      title,
      html,
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-danger me-2'
      }
    });
  }

  delete(objectType: string, objectName: string): Promise<SweetAlertResult> {
    return this.fire({
      title: `Delete ${objectType}`,
      html: `Are you sure you want to delete <strong>${objectName}</strong>?`,
      icon: 'error',
      cancelButtonText: 'No, return',
      confirmButtonText: 'Yes, delete!',
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-danger me-2',
        cancelButton: 'btn btn-light'
      }
    });
  }

  discard(): Promise<SweetAlertResult> {
    return this.fire({
      title: 'You have unsaved changes',
      html: 'Are you sure you want to discard changes?',
      icon: 'warning',
      cancelButtonText: 'No, return',
      confirmButtonText: 'Yes, discard them!',
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-danger me-2',
        cancelButton: 'btn btn-light'
      }
    });
  }

  error(title?: string, html?: string): Promise<SweetAlertResult> {
    return this.danger(title, html);
  }

  fire(options: Partial<SweetAlertOptions>): Promise<SweetAlertResult> {
    return this._swal.fire(options);
  }

  handleError(error: any): Promise<SweetAlertResult> {
    if (error instanceof HttpErrorResponse) {

      if (error.status === 403) {
        return this.danger('Forbidden', 'You do not have permission to perform this action.');
      }

      if (!!error.error?.title) {
        const errors = Object.values(error.error.errors).flat();
        return this.danger(error.error.title, `<ul class="text-start">${errors.map(e => `<li>${e}</li>`).join('')}</ul>`);
      }
    }

    if (Array.isArray(error)) {
      return this.danger('The following errors have occurred:', `<ul class="text-start">${error.map(e => `<li>${e}</li>`)}</ul>`);
    }

    if (typeof error === 'string') {
      return this.danger('Error', error);
    }

    return this.danger('Unknown Error', 'An unknown error has occurred.');
  }

  info(title?: string, html?: string): Promise<SweetAlertResult> {
    return this.fire({
      title,
      html,
      icon: 'info'
    });
  }

  prompt(title: string, label: string): Promise<SweetAlertResult> {
    return this.fire({
      title,
      input: 'text',
      inputLabel: label
    });
  }

  warn(title?: string, html?: string): Promise<SweetAlertResult> {
    return this.fire({
      title,
      html,
      icon: 'warning',
      customClass: {
        confirmButton: 'btn btn-warning me-2'
      }
    });
  }

}
